<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div @click="$router.go(-1)" class="flex cursor-pointer items-center">
      <div class="p-2 border border-opacity-25 rounded-md border-blue-500">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <div class="ml-2 text-sm text-black font-semibold">Back</div>
    </div>
    <h1 class="text-2xl font-bold mt-10">Pricing band portal</h1>
    <form @submit.prevent="handleBandAssignment" class="w-1/2">
      <div class="flex flex-col pt-4">
        <label for="email" class="">Business Name*</label>
        <input
          type="text"
          required
          disabled
          v-model="merchantName"
          class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
        />
      </div>
      <div class="flex flex-col pt-4">
        <label for="email" class="">Business ID*</label>
        <input
          type="text"
          required
          disabled
          v-model="business_id"
          class="shadow appearance-none text-xs border rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
        />
      </div>
      <div class="flex flex-col pt-4">
        <label for="password" class="">Pricing Band</label>
        <select
          type="text"
          required
          v-model="selectedPricingBand"
          class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
        >
          <option value="" disabled> Select Pricing Band</option>
          <option :value="band" v-for="(band, i) in bands" :key="i">{{
            band.transaction_fees_group_name
          }}</option>
        </select>
      </div>
      <div class="flex flex-col pt-4" v-if="selectedPricingBand">
        <p>ID: {{ selectedPricingBand._id }}</p>
        <p>Loan Fee Percent: {{selectedPricingBand.loan_fee_percent}}</p>
        <p v-if="selectedPricingBand !== null" class="bg-blue-50 my-3 p-3 rounded-md">
          <span class="font-semibold">Fee Name:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[0].trxn_fee_name
          }}
          <br/>
          <span class="font-semibold">Fee Percent:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[0].trxn_fee_percent
          }}
          <br/>
          <span class="font-semibold">Transaction Fee Threshold For Flat Fee:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[0]
                .trxn_fee_threshold_for_flat_fee
          }}
          <br/>
          <span class="font-semibold">Fee Type:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[0]
                .fee_type
          }}
          <br/>
            <span class="font-semibold">Cap Fee NGN:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[0]
                .cap_fee_ngn
          }}
          <br/>
            <span class="font-semibold">Flat Fee Type Conditions:</span>
            <ul v-for="(condition, i) in selectedPricingBand.transaction_fees_data[0]
                .flat_fee_type_conditions" :key="i">
                  <li>{{ condition.flat_fee_condition }}
                  </li>
                  <li>
                    {{ condition.flat_fee_amount }}
                  </li>
            </ul>
          
        </p>
        <p v-if="selectedPricingBand !== null" class="bg-blue-50 my-3 p-3 rounded-md">
          <span class="font-semibold">Fee Name:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[1].trxn_fee_name
          }}
          <br/>
          <span class="font-semibold">Fee Percent:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[1].trxn_fee_percent
          }}
          <br/>
          <span class="font-semibold">Transaction Fee Threshold For Flat Fee:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[1]
                .trxn_fee_threshold_for_flat_fee
          }}
          <br/>
          <span class="font-semibold">Fee Type:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[1]
                .fee_type
          }}
          <br/>
            <span class="font-semibold">Cap Fee NGN:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[1]
                .cap_fee_ngn
          }}
          <br/>
          <span class="font-semibold">Flat Fee Type Conditions:</span>
            <ul v-for="(condition, i) in selectedPricingBand.transaction_fees_data[1]
                .flat_fee_type_conditions" :key="i">
                  <li>{{ condition.flat_fee_condition +" | "+condition.flat_fee_amount }}
                  </li>
            </ul>
        </p>
        <p v-if="selectedPricingBand !== null" class="bg-blue-50 my-3 p-3 rounded-md">
          <span class="font-semibold">Fee Name:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[2].trxn_fee_name
          }}
          <br/>
          <span class="font-semibold">Fee Percent:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[2].trxn_fee_percent
          }}
          <br/>
          <span class="font-semibold">Transaction Fee Threshold For Flat Fee:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[2]
                .trxn_fee_threshold_for_flat_fee
          }}
          <br/>
          <span class="font-semibold">Fee Type:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[2]
                .fee_type
          }}
          <br/>
            <span class="font-semibold">Cap Fee NGN:</span>
          {{
            selectedPricingBand &&
              selectedPricingBand.transaction_fees_data[2]
                .cap_fee_ngn
          }}
          <br/>
            <span class="font-semibold">Flat Fee Type Conditions:</span>
            <ul v-for="(condition, i) in selectedPricingBand.transaction_fees_data[2]
                .flat_fee_type_conditions" :key="i">
                  <li>{{ condition.flat_fee_condition +" | "+condition.flat_fee_amount }}
                  </li>
            </ul>
          
        </p>
      </div>
      <button
        class="bg-blue-600 py-3 mt-5 px-6 rounded-md text-xs text-white"
        type="submit"
      >
        Assign Band
      </button>
    </form>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  data() {
    return {
      search: null,
      isLoading: false,
      bands: [],
      selectedPricingBand: "",
      business_id: null,
      merchantName: null,
    };
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      let res = await this.$store.dispatch("FETCH_PRICING_BAND_LISTS");
      this.isLoading = false;
      if (res.data) {
        this.bands = res.data.items;
      }
    },
    async handleBandAssignment() {
      const response = confirm(
        "Are you sure you want to assign " +
          this.selectedPricingBand.transaction_fees_group_name +
          " to " +
          this.merchantName
      );
      if (response) {
        this.isLoading = true;
        let payload = {
          pricing_band_id: this.selectedPricingBand._id,
          business_id: this.business_id,
        };
        let res = await this.$store.dispatch(
          "ASSIGN_PRICING_BAND_LISTS",
          payload
        );
        this.isLoading = false;
        if (res.status) {
          alert(res.message);
        } else {
          alert(res.message);
        }
      }
    },
  },
  async created() {
    this.fetchRecords();
    this.business_id = this.$route.params.id;
    this.merchantName = this.$route.params.merchant;
  },
};
</script>

<style scoped></style>
